import {InputFailure} from "./InputFailure";
import {ValueObject} from "./ValueObject";

export class AnswerDescriptionValueObject extends ValueObject<string> {
    _validate(value: string): InputFailure<string> | string {
        if (value.length === 0) return new EmptyDescriptionFailure(value);
        return value;
    }
}

export class EmptyDescriptionFailure extends InputFailure<string> {
    constructor(failedValue: string) {
        super(failedValue);
    }
}
