import * as React from 'react';
import {useState} from 'react';
import {TextField} from "@mui/material";
import {InputError} from "../InputError";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../../redux/store";

type Props = {
  value: string
};

function validateDescription(description: string): boolean | InputError {
  if (description.length === 0) {
    return InputError.EMPTY_DESCRIPTION;
  }
  return true;
}

export const DescriptionInput = (props: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<Dispatch>();
  const [descriptionHasError, setDescriptionHasError] = useState<boolean>(false);
  const [descriptionErrorDescription, setDescriptionErrorDescription] = useState<string>("");

  return (
    <TextField
      value={props.value}
      error={descriptionHasError}
      helperText={descriptionErrorDescription}
      label={t('creationPage.assessmentDetails.descriptionInput.descriptionLabelName')}
      fullWidth
      multiline
      rows={4.2}
      onChange={({target: {value}}) => {
        const result: boolean | InputError = validateDescription(value);

        if (result === true) {
          setDescriptionHasError(false);
          setDescriptionErrorDescription("");
        } else {
          setDescriptionHasError(true);
          setDescriptionErrorDescription(result as InputError);
        }
        dispatch.assessmentCreation.setDescription(value);
      }}
    />
  );
};
