import * as React from 'react';
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../../../../../redux/store";
import {AnswerDescriptionValueObject} from "../../../../../../../domain/value_objects/AnswerDecriptionValueObject";
import {InputFailure} from "../../../../../../../domain/value_objects/InputFailure";
import {toCamelCase} from "../../../../../../../utils/string-utils";
import {PayloadUpdateAnswerDescription} from "../../../../../../redux/payloads/payload-update-answer-description";


type Props = {
    description: AnswerDescriptionValueObject;
    index: number;
};

export const AnswerDescriptionInput = (props: Props) => {
    const {t} = useTranslation();

    const dispatch = useDispatch<Dispatch>();

    const showErrors: boolean = useSelector((state: RootState) => state.answerCreation?.showErrors);
    const indexQuestion: number = useSelector((state:RootState)=> state.questionCreation?.currentPage) - 1;

    const descriptionHasError: boolean = props.description.value instanceof InputFailure;
    const errorAsLowercaseString: string = toCamelCase((props.description.value as InputFailure<string>).constructor.name);

    return (
        <TextField
            fullWidth
            multiline
            value={descriptionHasError ? (props.description.value as InputFailure<string>).failedValue : (props.description.value as string)}
            label={t('creationPage.answerDisplay.descriptionInput.label')}
            error={descriptionHasError && showErrors}
            helperText={descriptionHasError && showErrors
                ? t(`creationPage.answerDisplay.descriptionInput.error.${errorAsLowercaseString}`)
                : undefined
            }
            rows={2}
            onChange={({target: {value}}) => {
                dispatch.answerCreation.updateAnswerDescription({
                    indexQuestion: indexQuestion,
                    indexAnswer: props.index,
                    input: value,
                } as PayloadUpdateAnswerDescription);
            }}
        />
    );
};
