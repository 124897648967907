import {Env} from "./Env";

export class Environment {

  static ENV: Env = Environment._getEnv(process.env.REACT_APP_ENV || 'LOCAL');
  static REMOTE_URL: string = process.env.REACT_APP_REMOTE_URL || 'http://localhost:8080';

  private static _getEnv(environmentVariable: string): Env {
    if (environmentVariable.toUpperCase() === Env.REMOTE) {
      return Env.REMOTE   ;
    } else {
      return Env.LOCAL;
    }
  }

}
