import {DifficultyRanking, getRankingByValue} from "../entities/DifficultyRanking";
import {InputFailure} from "./InputFailure";
import {ValueObject} from "./ValueObject";

export class QuestionDifficultyRankingValueObject extends ValueObject<DifficultyRanking | string> {
  _validate(value: DifficultyRanking): InputFailure<DifficultyRanking | string> | DifficultyRanking {
    if (value.length === 0) return new EmptyRankingFailure(value);

    const ranking = getRankingByValue(value);

    if (!ranking) return new UnknownRankingFailure(value);

    return ranking;
  }
}

export class EmptyRankingFailure extends InputFailure<string> {
  constructor(failedValue: string) {
    super(failedValue);
  }
}

export class UnknownRankingFailure extends InputFailure<DifficultyRanking> {
  constructor(failedValue: DifficultyRanking) {
    super(failedValue);
  }
}
