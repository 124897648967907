import {inject, injectable} from "tsyringe";
import AuthenticationRepository from "../../repositories/AuthenticationRepository";
import {Credentials} from "../../entities/Credentials";

@injectable()
export class LoginUseCase {

    constructor(
        @inject('AuthenticationRepository') private _authenticationRepository: AuthenticationRepository
    ) {
    }

    async execute(payload: Credentials): Promise<string> {
        return this._authenticationRepository.login(payload.email, payload.password);
    }

}