import * as React from 'react';
import {Tooltip, Typography} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid";

import {Assessment} from "../../../../domain/entities/Assessment";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../redux/store";

import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from "react-i18next";

type Props = {
    assessment: Assessment,
};

export function DeleteButton(props: Props) {
    const {t} = useTranslation();

    const dispatch = useDispatch<Dispatch>();

    return (
        <GridActionsCellItem
            icon={
                <Tooltip title={
                    <Typography>
                        {t('assessmentTable.deleteButton.label')}
                    </Typography>
                }>
                    <DeleteIcon/>
                </Tooltip>
            }
            onClick={() => {
                dispatch.assessments.deleteAssessment(props.assessment);
            }}
            label={t('assessmentTable.deleteButton.label')}/>
    );
}
