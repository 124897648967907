import * as React from 'react';
import {MenuItem, TextField} from "@mui/material";
import {DifficultyRanking} from "../../../../../../domain/entities/DifficultyRanking";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../../../../redux/store";
import {
  QuestionDifficultyRankingValueObject
} from "../../../../../../domain/value_objects/QuestionDifficultyRankingValueObject";
import {InputFailure} from "../../../../../../domain/value_objects/InputFailure";
import {toCamelCase} from "../../../../../../utils/string-utils";

type Props = {
  ranking: QuestionDifficultyRankingValueObject;
};

export const DifficultyRankingSelect = (props: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<Dispatch>();

  const showErrors: boolean = useSelector((state: RootState) => state.questionCreation?.showErrors);

  const rankingHasError: boolean = props.ranking.value instanceof InputFailure;
  const errorAsLowercaseString: string = toCamelCase((props.ranking.value as InputFailure<string>).constructor.name);

  const difficultyRankings: [string, DifficultyRanking][] = Object.entries(DifficultyRanking);

  return (
    <TextField
      id="difficulty-ranking-select"
      select
      label={t('creationPage.questionDisplay.difficultyRankingSelect.label')}
      value={rankingHasError ? (props.ranking.value as InputFailure<string>).failedValue : (props.ranking.value as string)}
      error={rankingHasError && showErrors}
      helperText={
        rankingHasError && showErrors
          ? t(`creationPage.questionDisplay.difficultyRankingSelect.error.${errorAsLowercaseString}`)
          : undefined
      }
      onChange={({target: {value}}) => {
        dispatch.questionCreation.updateCurrentQuestionRanking(value);
      }}
      sx={{minWidth: 210}}
    >
      {difficultyRankings.map(
        ([key, ranking]) => {
          return <MenuItem key={key} value={ranking}>
            {t(`creationPage.questionDisplay.difficultyRankingSelect.${key.toLowerCase()}`)}
          </MenuItem>;
        }
      )}
    </TextField>
  );
};
