import {inject, injectable} from "tsyringe";
import AuthenticationRepository from "../../repositories/AuthenticationRepository";

@injectable()
export class LogoutUseCase {

    constructor(
        @inject('AuthenticationRepository') private _authenticationRepository: AuthenticationRepository
    ) {
    }

    async execute(): Promise<boolean> {
        return this._authenticationRepository.logout();
    }

}