import * as React from 'react';
import {Button, Grid, Paper} from "@mui/material";
import {AssessmentDetails} from "./AssessmentDetails/AssessmentDetails";
import {QuestionCreation} from "./QuestionCreation/QuestionCreation";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Header} from "./Header/Header";
import {UrlRoutes} from "../utils/UrlRoutes"

type Props = {};

export const CreationPage = (_: Props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
      <Grid
          container
          height="100%"
          direction="column"
          rowSpacing={2}
          padding="2vw"
          wrap='nowrap'
      >
        <Grid item flex={1}>
          <Header
              title={t('creationPage.title')}
              subTitle={t('creationPage.subtitle')}
          />
        </Grid>
        <Grid item>
          <Paper
              elevation={1}
              sx={{
                padding: "2vh"
              }}>
            <Grid item container flex="2" spacing={2} columns={12}>
              <AssessmentDetails/>
            </Grid>
            <Grid item container flex="2" spacing={2} columns={12} sx={{
              marginTop: "7vh"
            }}>
              <QuestionCreation/>
            </Grid>
          </Paper>
        </Grid>
        <Grid item flex={1} container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
                variant="contained"
                size="medium"
                color="success"
            >
              {t('creationPage.saveButton')}
            </Button>
          </Grid>
          <Grid item>
          <Button
            variant="outlined"
            color="error"
            size="medium"
            onClick={() => navigate(UrlRoutes.HOME_PAGE)}
          >
            {t('creationPage.cancelButton')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
