import * as React from 'react';
import {useState} from 'react';
import {LanguageSelect} from "../shared/LanguageSelect/LanguageSelect";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography
} from "@mui/material";

import {styled} from '@mui/material/styles';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../redux/store";
import {useTranslation} from "react-i18next";
import {Credentials} from "../../../domain/entities/Credentials";

const BackgroundImg = styled('img')({
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100vh',
});

const SmallImg = styled('img')({
    display: 'block',
    maxWidth: '50%',
    maxHeight: '50%',
});

const lightText = {fontWeight: 400, fontSize: 16, color: '#8F8F8F'};
const smallLightText = {fontWeight: 400, fontSize: 14, color: '#8F8F8F'};


export function LoginPage() {
    const [pwdVisible, setPwdVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [emailIsEmpty, setEmailIsEmpty] = useState<boolean>(false);
    const [pwdIsEmpty, setPwdIsEmpty] = useState<boolean>(false);
    const dispatch = useDispatch<Dispatch>();
    const {t} = useTranslation();
    const error = useSelector((state: RootState) => state.authentication?.error);

    function handleClickShowPassword() {
        setPwdVisible(!pwdVisible);
    }

    function handleLogin() {
        let emailIsEmpty: boolean;
        let pwdIsEmpty: boolean;
        emailIsEmpty = validation(email);
        setEmailIsEmpty(emailIsEmpty);
        pwdIsEmpty = validation(pwd);
        setPwdIsEmpty(pwdIsEmpty);
        dispatch.authentication.setError(false);

        if (!emailIsEmpty && !pwdIsEmpty) {

            setEmailIsEmpty(false);
            setPwdIsEmpty(false);

            const payload: Credentials = {email: email, password: pwd}
            dispatch.authentication.login(payload);
        }
    }

    function handleEmailInput(event: any) {
        setEmail(event.target.value);
    }

    function handlePwdInput(event: any) {
        setPwd(event.target.value);
    }

    function validation(fieldName: string) {
        return fieldName === '';
    }


    return (
        <Grid container height="100vh" spacing={2}>
            <Grid item xs={7}>
                <BackgroundImg src="/background_image.png"/>
            </Grid>

            <Grid container item xs={3} direction="column" spacing={2} style={{margin: "1vh"}}>
                <Grid item container xs={1} justifyContent={'flex-end'}>
                    <LanguageSelect/>
                </Grid>
                <Grid item container xs={1} justifyContent={'center'}>
                    <img src={'company_logo.png'} alt={t('loginPage.companyLogo')}/>
                </Grid>
                <Grid item xs={1}>
                    <Typography sx={{fontWeight: 500, fontSize: 24}}>{t('loginPage.welcomeText')}</Typography>
                    <Typography sx={lightText}>{t('loginPage.askingLoginText')}</Typography>
                </Grid>

                <Grid xs={4} item container direction={"column"} spacing={2} justifyContent={"space-around"}>
                    <Grid item>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth={true}
                            onChange={handleEmailInput}
                            placeholder={t(`loginPage.placeholderEmail`)}
                            error={error || emailIsEmpty}
                            helperText={emailIsEmpty ? t(`loginPage.error.emptyFields`) : ''}
                            FormHelperTextProps={{style: {color: 'red', marginLeft: 0}}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type={pwdVisible ? 'text' : 'password'}
                            fullWidth={true}
                            onChange={handlePwdInput}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {pwdVisible ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t(`loginPage.placeholderPassword`)}
                            error={pwdIsEmpty || error}
                            helperText={pwdIsEmpty ? t(`loginPage.error.emptyFields`) : error && !emailIsEmpty ? t(`loginPage.error.loginError`) : ''}
                            FormHelperTextProps={{style: {color: 'red', marginLeft: 0}}}
                        />
                    </Grid>
                    <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <FormControlLabel control={<Checkbox defaultChecked/>} label={t<string>('loginPage.keepLoggedLabel')}/>
                        </Grid>
                        <Grid item>
                            <Link href="#">{t(`loginPage.forgotPasswordLink`)}</Link>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" fullWidth onClick={handleLogin}>{t(`loginPage.loginButton`)}</Button>
                    </Grid>
                </Grid>


                <Grid item container xs={1} justifyContent={'space-evenly'}>
                    <Grid item>
                        <Typography>{t(`loginPage.HaveNoAccountQuestion`)}</Typography>
                    </Grid>
                    <Grid item>
                        <Link href={"#"}>{t(`loginPage.registerLink`)}</Link>
                    </Grid>
                </Grid>

                <Grid item container xs={1} justifyContent={'flex-end'} alignItems={'flex-end'} >
                    <Grid item container xs={12} direction='row' justifyContent={'center'} alignItems={'center'}>
                        <Grid item xs={5} style={{display:"flex", justifyContent:"flex-end"}}>
                            <Typography sx={smallLightText}>Powered by</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SmallImg src={t(`loginPage.companyLogoPng`)}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}
