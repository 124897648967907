import {MenuItem, Select, Typography} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import * as React from "react";
import {styled} from "@mui/material/styles";

const Div = styled('div')({
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center"
});

const countryFlagStyle = {
    marginRight:8,
}

const typographySx = {
    fontSize: 12,
    fontWeight:400,
}

export function LanguageSelect() {
    return (<Select
        size="small"
        id={"language-select"}
        value={"pt_br"}
        label={"Language"}
    >
        <MenuItem value={"pt_br"}>
            <Div>
                <ReactCountryFlag countryCode="BR" svg style={countryFlagStyle}/>
                <Typography sx={typographySx}>Português</Typography>
            </Div>
        </MenuItem>
        <MenuItem value={"en_us"}>
            <Div>
                <ReactCountryFlag countryCode="US" svg style={countryFlagStyle}/>
                <Typography sx={typographySx}>English</Typography>
            </Div>
        </MenuItem>
    </Select>);
}
