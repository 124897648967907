import {AnswerDescriptionValueObject} from "../value_objects/AnswerDecriptionValueObject";


export class Answer{
    id?: string;
    description: AnswerDescriptionValueObject;
    isCorrect: boolean;

    constructor(description: AnswerDescriptionValueObject, isCorrect: boolean, id?: string) {
        this.id = id;
        this.description = description;
        this.isCorrect = isCorrect;
    }

}