import {InputFailure} from "./InputFailure";

export abstract class ValueObject<T> {
  value: T | InputFailure<T>;

  constructor(value: T) {
    this.value = this._validate(value);
  }

  abstract _validate(value: T): T | InputFailure<T>;
}
