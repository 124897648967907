// @flow
import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../../../../redux/store";
import {Button, Divider, Grid, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {Answer} from "../../../../../../domain/entities/Answer";
import {AnswerDisplay} from "./AnswerDisplay/AnswerDisplay";


type Props = {
};
export const AnswerCreation = (_: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch<Dispatch>();
    const answers: Answer[][] = useSelector((state: RootState) => state.answerCreation?.answers);
    const indexQuestion: number = useSelector((state:RootState)=> state.questionCreation?.currentPage) -1;
    const answerAmount: number = answers[indexQuestion].length;
    const currentAnswer: Answer | undefined = answers[indexQuestion][answerAmount - 1];
    return (
        <>
            <Grid item xs={10}>
                <Typography variant="h6" sx={{}}>
                    {t('creationPage.answerCreation.sectionTitle')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{
                    marginBottom: "1vh"
                }}/>
            </Grid>
            <Grid item container direction="column" xs={12} spacing={2}>
                <Grid item container justifyContent="space-between">
                    <Button
                        variant="contained" startIcon={<AddIcon/>}
                        onClick={() => dispatch.answerCreation.addAnswer(indexQuestion)}
                    >
                        {t('creationPage.answerCreation.newAnswerButton')}
                    </Button>
                </Grid>
                {currentAnswer !== undefined && (
                    <>
                        {answers[indexQuestion].map((answer, i) =>
                            <Grid item>
                                <AnswerDisplay
                                    answer={answer}
                                    answerNumber={i}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </>
    )
};
