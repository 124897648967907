import "reflect-metadata";
import "./index.css";
import * as React from 'react';
import ReactDOM from 'react-dom';
import './i18n/config';
import CssBaseline from '@mui/material/CssBaseline';
import App from './presentation/components/App';
import {store} from "./presentation/redux/store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
    <React.Fragment>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline/>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.Fragment>,
    document.getElementById('root'),
);
