import * as React from 'react';
import {useState} from 'react';
import {InputError} from "../InputError";
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../../redux/store";

type Props = {
  value: number
};

const TIME_LIMIT_MINIMUM_VALUE = 1;

function validateTimeLimit(timeLimit: string): boolean | InputError {
  const convertedTimeLimit: number = Number(timeLimit);

  if (isNaN(convertedTimeLimit)) {
    return InputError.TIME_LIMIT_NON_NUMERIC;
  }

  if (timeLimit.length === 0) {
    return InputError.EMPTY_TIME_LIMIT;
  }

  if (convertedTimeLimit < TIME_LIMIT_MINIMUM_VALUE) {
    return InputError.TIME_LIMIT_BELOW_MINIMUM;
  }

  return true;
}

export function TimeLimitInput(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch<Dispatch>();

  const [timeLimitHasError, setTimeLimitHasError] = useState<boolean>(false);
  const [timeLimitErrorDescription, setTimeLimitErrorDescription] = useState<string>("");

  return (
    <TextField
      value={props.value}
      error={timeLimitHasError}
      helperText={timeLimitErrorDescription}
      label={t('creationPage.assessmentDetails.timeLimitInput.timeLimitLabelName')}
      fullWidth
      InputProps={{inputProps: {min: 1}}}
      onChange={({target: {value}}) => {
        const result: boolean | InputError = validateTimeLimit(value);

        if (result === true) {
          setTimeLimitHasError(false);
          setTimeLimitErrorDescription("");
        } else {
          setTimeLimitHasError(true);
          setTimeLimitErrorDescription(result as InputError);
        }

        if (result !== InputError.TIME_LIMIT_NON_NUMERIC) {
          dispatch.assessmentCreation.setTimeLimit(Number(value));
        }
      }}
    />
  );
}
