import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../redux/store";
import {DataGrid, GridRowParams} from "@mui/x-data-grid";
import {Assessment} from "../../../domain/entities/Assessment";
import {DeleteButton} from "./DeleteButton/DeleteButton";
import {Button, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {UrlRoutes} from '../utils/UrlRoutes';

type Props = {};

export function AssessmentTable(_: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const assessments: Assessment[] = useSelector((state: RootState) => state.assessments?.assessments);
    const isLoading: boolean = useSelector((state: RootState) => state.assessments?.isLoading);
    const dispatch = useDispatch<Dispatch>();

  const columns = [
    {
      field: 'title',
      headerName: t('assessmentTable.titleField'),
      flex: 2,
    },
    {
      field: 'description',
      headerName: t('assessmentTable.descriptionField'),
      flex: 3,
    },
    {
      field: 'timeLimit',
      headerName: t('assessmentTable.timeLimitField'),
      type: 'number',
      flex: 1,
    },
    {
      field: 'numberOfQuestions',
      headerName: t('assessmentTable.numberOfQuestionsField'),
      type: 'number',
      flex: 1,
    },
    {
      field: '',
      type: 'actions',
      headerName: t('assessmentTable.actions'),
      getActions: (params: GridRowParams) => [
        <DeleteButton assessment={params.row as Assessment}/>,
      ]
    }
  ];

    useEffect(() => {
        dispatch.assessments.loadAssessments();
    }, []);

    return (
        <>
            <Grid
                item
                container
                alignItems='end'
                justifyContent='space-between'
            >
                <Grid item>
                    <Typography>
                        {t('assessmentTable.tableTitle')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(UrlRoutes.ASSESSMENT_CREATION)}
                    >
                        {t('assessmentTable.newAssessmentButton')}
                    </Button>
                </Grid>
            </Grid>
            <Grid item container flexGrow={1}>
                <DataGrid
                    loading={isLoading}
                    rows={assessments}
                    columns={columns}
                    autoPageSize
                />
            </Grid>
        </>
    );
}
