import {Assessment} from "../../domain/entities/Assessment";
import AssessmentRepository from "../../domain/repositories/AssessmentRepository";
import axios, {AxiosInstance, AxiosResponse} from "axios";
import {AssessmentDto} from "../dto/AssessmentDto";
import {AssessmentDtoMapper} from "../mapper/AssessmentDtoMapper";
import {HttpStatus} from "../utils";
import {LocalStorage} from "../../domain/repositories/LocalStorage";

export class AssessmentRepositoryApiImplementation implements AssessmentRepository {

  private readonly _URL: string;
  private _instance: AxiosInstance;

  constructor(URL: string) {
    this._URL = URL;
    this._instance = axios.create({
      baseURL: this._URL,
      timeout: 1000,
    });
  }

  async fetchAllAssessments(token: string): Promise<Assessment[]> {
    try {
      const response: AxiosResponse<AssessmentDto[]> = await this._instance.get<AssessmentDto[]>(
        "/assessments",{ headers: { Authorization: token }});
      return response.data.map(dto => AssessmentDtoMapper.dtoToEntity(dto));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error && error.response) {

          return error.response.data;
        }
      }
      return [];
    }
  }

  async deleteAssessment(idToBeDeleted?: string): Promise<boolean> {
    try {
      const currentUser = LocalStorage.getCurrentUser();
      const response: AxiosResponse<void> = await this._instance.delete<void>(
          `/assessments/${idToBeDeleted}`, { headers: { Authorization: currentUser.userToken }}
      );

      return response.status === HttpStatus.NO_CONTENT;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error && error.response) {
          return error.response.data;
        }
      }
      return false;
    }
  }
}
