import {Models} from "@rematch/core";
import {count} from "./count";
import {assessments} from "./assessments";
import {authentication} from "./authentication";
import {assessmentCreation} from "./assessment-creation";
import {questionCreation} from "./question-creation";
import {answerCreation} from "./answer-creation";

export interface RootModel extends Models<RootModel> {
    count: typeof count;
    assessments: typeof assessments;
    authentication: typeof authentication;
    assessmentCreation: typeof assessmentCreation;
    questionCreation: typeof questionCreation;
    answerCreation: typeof answerCreation;
}

export const models: RootModel = {
    count,
    assessments,
    authentication,
    assessmentCreation,
    questionCreation,
    answerCreation,
};
