import AssessmentRepository from "../../domain/repositories/AssessmentRepository";
import {Assessment} from "../../domain/entities/Assessment";

export class AssessmentRepositoryMockImplementation implements AssessmentRepository {
  private static assessments = [
    new Assessment(
      'ad84a9f6-fa73-4963-8a39-94e5b901dae3',
      "Java Assessment",
      "Interesting assessment",
      20,
      30
    ),
    new Assessment(
      'ded54f99-917a-4eae-9840-a9986351bd7b',
      "Python Assessment",
      "Another assessment",
      45,
      50
    ),
    new Assessment(
      '510a0c5a-da07-4b62-ac7e-0988fbdcad60',
      "C++ Assessment",
      "Third assessment",
      60,
      100
    ),
  ];

  async fetchAllAssessments(): Promise<Assessment[]> {
    return Promise.resolve(AssessmentRepositoryMockImplementation.assessments);
  }

  async deleteAssessment(idToBeDeleted?: string): Promise<boolean> {
    AssessmentRepositoryMockImplementation.assessments = AssessmentRepositoryMockImplementation.assessments
      .filter(assessment => assessment.id !== idToBeDeleted);

    return true;
  }

}
