import * as React from 'react';
import {useState} from 'react';
import {TextField} from "@mui/material";
import {InputError} from "../InputError";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../../../redux/store";

type Props = {
  value: string,
};

const TITLE_MAX_LENGTH = 100;

function validateTitle(title: string): boolean | InputError {
  if (title.length === 0) {
    return InputError.EMPTY_TITLE;
  }
  if (title.length > TITLE_MAX_LENGTH) {
    return InputError.OVER_LIMIT;
  }

  return true;
}

export const TitleInput = (props: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<Dispatch>();

  const [titleHasError, setTitleHasError] = useState<boolean>(false);
  const [titleErrorDescription, setTitleErrorDescription] = useState<string>("");

  return (
    <TextField
      error={titleHasError}
      value={props.value}
      label={t('creationPage.assessmentDetails.titleInput.titleLabelName')}
      helperText={titleErrorDescription}
      fullWidth
      onChange={({target: {value}}) => {
        const result: boolean | InputError = validateTitle(value);

        if (result === true) {
          setTitleHasError(false);
          setTitleErrorDescription("");
        } else {
          setTitleHasError(true);
          setTitleErrorDescription(result as InputError);
        }

        dispatch.assessmentCreation.setTitle(value);
      }}
    />
  );
};
