import axios, {AxiosInstance, AxiosResponse} from "axios";
import AuthenticationRepository from "../../domain/repositories/AuthenticationRepository";
import {PayloadLoginResponse} from "../../presentation/redux/payloads/payload-login-response";
import jwt_decode from 'jwt-decode';

const API_URL = "/authenticate";

export class AuthenticationRepositoryImplementation implements AuthenticationRepository {

    private readonly _URL: string;
    private _instance: AxiosInstance;

    constructor(URL: string) {
        this._URL = URL;
        this._instance = axios.create({
            baseURL: this._URL,
            timeout: 1000,
        });
    }


    async login(email: string, password: string): Promise<string> {
        let configsJSON;
        try {
            const data = {email: email, password: password}
            const response: AxiosResponse<string> = await this._instance.post<string>(
                API_URL,
                data,
            );
            configsJSON = JSON.parse(response.config['data']);

            let token: string = response.headers['authorization'];

            const decodedToken = this.getDecodedAccessToken(token);
            let userName: string = configsJSON['email'];
            let userRole: string = decodedToken.role[0][0].authority;
            let tokenExpire: number = decodedToken.exp;

            let responseSessionPayload: PayloadLoginResponse = new PayloadLoginResponse(userName, token, userRole, tokenExpire);
            localStorage.setItem("user", JSON.stringify(responseSessionPayload));

            return Promise.resolve(JSON.stringify(responseSessionPayload));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error && error.response) {
                    return error.response.data;
                }
            }
            return Promise.resolve("");
        }
    }

    logout(): Promise<boolean> {
        localStorage.removeItem("user");
        window.location.reload();
        return Promise.resolve(false);
    }

    getDecodedAccessToken(token: string): any {
        try{
            return jwt_decode(token);
        }
        catch(Error){
            return "";
        }
    }

}
