import {Assessment} from "../../entities/Assessment";
import {inject, injectable} from "tsyringe";
import AssessmentRepository from "../../repositories/AssessmentRepository";
import {LocalStorage} from "../../repositories/LocalStorage";
@injectable()
export class FetchAllAssessmentsUseCase {

  constructor(
    @inject('AssessmentRepository') private _assessmentRepository: AssessmentRepository
  ) {
  }

  async execute(): Promise<Assessment[]> {
    const currentUser = LocalStorage.getCurrentUser();
    const token = currentUser?.userToken || '';
    return this._assessmentRepository.fetchAllAssessments(token);
  }

}
