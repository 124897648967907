export class Assessment {
  id?: string;
  title: string;
  description: string;
  timeLimit: number;
  numberOfQuestions: number;

  constructor(id: string, title: string, description: string, timeLimit: number, numberOfQuestions: number) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.timeLimit = timeLimit;
    this.numberOfQuestions = numberOfQuestions;
  }
}
