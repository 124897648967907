import AssessmentRepository from "../../repositories/AssessmentRepository";
import {Assessment} from "../../entities/Assessment";
import {inject, injectable} from "tsyringe";

@injectable()
export class DeleteAssessmentUseCase {
  constructor(
    @inject('AssessmentRepository') private _assessmentRepository: AssessmentRepository
  ) {
  }

  async execute(assessmentToBeDeleted: Assessment): Promise<boolean> {
    return this._assessmentRepository.deleteAssessment(assessmentToBeDeleted.id);
  }
}
