import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import {container} from "tsyringe";
import {LoginUseCase} from "../../../domain/use_cases/LoginUseCase/LoginUseCase";
import {PayloadLoginResponse} from "../payloads/payload-login-response";
import {LogoutUseCase} from "../../../domain/use_cases/LogoutUseCase/LogoutUseCase";
import {Credentials} from "../../../domain/entities/Credentials";

const loginUseCase: LoginUseCase = container.resolve(LoginUseCase);
const logoutUseCase: LogoutUseCase = container.resolve(LogoutUseCase);

export type LoginState = {
    name: string,
    email: string,
    role: string,
    token: string,
    isLoading: boolean,
    error: boolean,
}

export const authentication = createModel<RootModel>()({
    state: {
        name: '',
        token: '',
        email: '',
        role: '',
        isLoading: false,
        error: false,
    } as LoginState,
    reducers: {
        setResponseLoginPayload(state: LoginState, payload: PayloadLoginResponse): LoginState {
            return {
                ...state,
                name: payload.userName,
                token: payload.userToken,
                role: payload.userRole,
            }
        },
        setIsLoading(state: LoginState, payload: boolean): LoginState {
            return {
                ...state,
                isLoading: payload,
            }
        },
        setError(state: LoginState, payload: boolean): LoginState {
            return {
                ...state,
                error: payload,
            }
        },
    },

    effects: (dispatch) => ({
        async login(payload: Credentials) {
            dispatch.authentication.setIsLoading(true);

            const response: string = await loginUseCase.execute(payload);

            if (response) {
                const responseJson: PayloadLoginResponse = JSON.parse(response);

                dispatch.authentication.setResponseLoginPayload(responseJson);
            } else {
                dispatch.authentication.setError(true);
                return;
            }
            dispatch.authentication.setIsLoading(false);
        },
        async logout() {
            return await logoutUseCase.execute();
        }
    }),
});
