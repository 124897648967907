import * as React from 'react';
import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../../../../redux/store";
import {QuestionDescriptionValueObject} from "../../../../../../domain/value_objects/QuestionDescriptionValueObject";
import {InputFailure} from "../../../../../../domain/value_objects/InputFailure";
import {toCamelCase} from "../../../../../../utils/string-utils";

type Props = {
  description: QuestionDescriptionValueObject;
};

export const DescriptionInput = (props: Props) => {
  const {t} = useTranslation();

  const dispatch = useDispatch<Dispatch>();

  const showErrors: boolean = useSelector((state: RootState) => state.questionCreation?.showErrors);

  const descriptionHasError: boolean = props.description.value instanceof InputFailure;
  const errorAsLowercaseString: string = toCamelCase((props.description.value as InputFailure<string>).constructor.name);

  return (
    <TextField
      fullWidth
      multiline
      value={descriptionHasError ? (props.description.value as InputFailure<string>).failedValue : (props.description.value as string)}
      label={t('creationPage.questionDisplay.descriptionInput.label')}
      error={descriptionHasError && showErrors}
      helperText={descriptionHasError && showErrors
        ? t(`creationPage.questionDisplay.descriptionInput.error.${errorAsLowercaseString}`)
        : undefined
      }
      rows={4.2}
      onChange={({target: {value}}) => {
        dispatch.questionCreation.updateQuestionDescription(value);
      }}
    />
  );
};
