import {Typography} from "@mui/material";
import * as React from "react";

type Props = {
  title: string,
  subTitle: string,
};

export function Header(props: Props) {
  return <>
    <Typography variant="h3">
      {props.title}
    </Typography>
    <Typography variant="subtitle1">
      {props.subTitle}
    </Typography>
  </>;
}
