import {createModel} from "@rematch/core";
import {RootModel} from "./index";

export const count = createModel<RootModel>()({
  state: 0, // initial state
  reducers: {
    // handle state changes with pure functions
    increment(state: number, payload: number): number {
      return state + payload;
    },
  },
});
