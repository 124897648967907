import * as React from 'react';
import {Grid} from "@mui/material";
import {DependencyInjection} from "../../utils/DependencyInjection";
import {LoginPage} from "./LoginPage/LoginPage";
import {Route, Routes} from "react-router-dom";
import {CreationPage} from "./CreationPage/CreationPage";
import {UrlRoutes} from "./utils/UrlRoutes";
import {HomePage} from "./HomePage/HomePage";
import {HomePageCandidate} from "./HomePage/HomePageCandidate";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {UserRoles} from "./utils/UserRoles";

DependencyInjection.inject();

export default function App() {
    const isLoading = useSelector((state: RootState) => state.authentication?.isLoading);

    let getCurrentUser = () => {
        const userStr = localStorage.getItem("user");
        if(userStr){
            if(JSON.parse(userStr).tokenExpire * 1000 - Date.now() <= 1){
                localStorage.removeItem("user");
                window.location.reload();
            }
        }
        return userStr && !isLoading ? JSON.parse(userStr) : null;
    }

    const currentUser = getCurrentUser();

    return (
        <Grid
            container
            direction="column"
            wrap='nowrap'
            sx={{
                height: "100%",
                color: {
                    backgroundColor: "#0050A60D",
                },
            }}
        >
            <Routes>
                <Route path={UrlRoutes.ASSESSMENT_CREATION} element={!currentUser ? <LoginPage/> : currentUser.userRole === UserRoles.ADMIN ? <CreationPage/> : <HomePageCandidate/>}/>
                <Route path={UrlRoutes.HOME_PAGE_CANDIDATE} element={!currentUser ? <LoginPage/> : currentUser.userRole === UserRoles.ADMIN ? <HomePage/> : <HomePageCandidate/>}/>
                <Route path={UrlRoutes.LOGIN_PAGE} element={!currentUser ? <LoginPage/> : currentUser.userRole === UserRoles.ADMIN ? <HomePage/> : <HomePageCandidate/>}/>
                <Route path={UrlRoutes.INDEX_PAGE} element={!currentUser? <LoginPage/> : currentUser.userRole === UserRoles.ADMIN ? <HomePage/> : <HomePageCandidate/>}/>
                <Route path={UrlRoutes.HOME_PAGE} element={!currentUser ? <LoginPage/> : currentUser.userRole === UserRoles.ADMIN ? <HomePage/> : <HomePageCandidate/>}/>
            </Routes>
        </Grid>
    );
}
