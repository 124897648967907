import {container} from "tsyringe";
import {AssessmentRepositoryMockImplementation} from "../data/impl/AssessmentRepositoryMockImplementation";
import AssessmentRepository from "../domain/repositories/AssessmentRepository";
import {Environment} from "./Environment";
import {Env} from "./Env";
import {AssessmentRepositoryApiImplementation} from "../data/impl/AssessmentRepositoryApiImplementation";
import AuthenticationRepository from "../domain/repositories/AuthenticationRepository";
import {AuthenticationRepositoryImplementation} from "../data/impl/AuthenticationRepositoryImplementation";

export class DependencyInjection {
  static inject(): void {
    container.register<AssessmentRepository>('AssessmentRepository', {
      useFactory: (_) => {
        if (Environment.ENV === Env.REMOTE) return new AssessmentRepositoryApiImplementation(Environment.REMOTE_URL);

        return new AssessmentRepositoryMockImplementation();
      }
    });

    container.register<AuthenticationRepository>('AuthenticationRepository', {
      useFactory: (_) => {
        if (Environment.ENV === Env.REMOTE) return new AuthenticationRepositoryImplementation(Environment.REMOTE_URL);

        return new AuthenticationRepositoryImplementation(Environment.REMOTE_URL); // TODO: replace with mock
      }
    });
  }
}
