import {QuestionDescriptionValueObject} from "../value_objects/QuestionDescriptionValueObject";
import {QuestionDifficultyRankingValueObject} from "../value_objects/QuestionDifficultyRankingValueObject";

export class Question {
  id?: string;
  description: QuestionDescriptionValueObject;
  difficultyRanking: QuestionDifficultyRankingValueObject;

  constructor(description: QuestionDescriptionValueObject, difficultyRanking: QuestionDifficultyRankingValueObject, id?: string,) {
    this.id = id;
    this.description = description;
    this.difficultyRanking = difficultyRanking;
  }
}
