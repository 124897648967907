export class PayloadLoginResponse {
    userName: string;
    userToken: string;
    userRole: string;
    tokenExpire: number;

    constructor(userName: string, userToken: string, userRole: string, tokenExpire: number) {
        this.userName = userName;
        this.userToken = userToken;
        this.userRole = userRole;
        this.tokenExpire = tokenExpire;
    }

}