import {createModel} from "@rematch/core";
import {RootModel} from "./index";

export type AssessmentCreationState = {
  title: string,
  description: string,
  timeLimit: number,
}

export const assessmentCreation = createModel<RootModel>()({
  state: {
    title: "",
    description: "",
    timeLimit: 1,
  } as AssessmentCreationState,
  reducers: {
    setTitle(state: AssessmentCreationState, payload: string): AssessmentCreationState {
      return {
        ...state,
        title: payload,
      }
    },
    setDescription(state: AssessmentCreationState, payload: string): AssessmentCreationState {
      return {
        ...state,
        description: payload,
      }
    },
    setTimeLimit(state: AssessmentCreationState, payload: number): AssessmentCreationState {
      return {
        ...state,
        timeLimit: payload,
      }
    },
  },
});
