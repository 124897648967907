import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import {Answer} from "../../../domain/entities/Answer";
import {AnswerDescriptionValueObject} from "../../../domain/value_objects/AnswerDecriptionValueObject";
import {PayloadInsertAnswer} from "../payloads/payload-insert-answer";
import {PayloadUpdateAnswerDescription} from "../payloads/payload-update-answer-description";


export type AnswerCreationState = {
    showErrors: boolean,
    answers: Answer[][],
}

export const answerCreation = createModel<RootModel>()({
    state: {
        showErrors: false,
        answers: [],
    } as AnswerCreationState,

    reducers: {
        setShowError(state: AnswerCreationState, shouldShowErrors: boolean): AnswerCreationState {
            return {
                ...state,
                showErrors: shouldShowErrors,
            };
        },
        insertAnswer(state: AnswerCreationState, payload: PayloadInsertAnswer): AnswerCreationState {
            const updatedState = {
                ...state,
                answers: [...state.answers]
            };

            updatedState.answers[payload.indexQuestion].push(payload.answer);

            return updatedState;
        },
        updateAnswerDescription(state: AnswerCreationState, payload: PayloadUpdateAnswerDescription): AnswerCreationState {
            const updatedState = {
                ...state,
                answers: [...state.answers]
            };

            updatedState.answers[payload.indexQuestion] = state.answers[payload.indexQuestion].map((answer, index) => {
                if (index === (payload.indexAnswer)) {
                    answer.description = new AnswerDescriptionValueObject(payload.input);
                }

                return answer;
            });


            return updatedState;
        },
        addAnswerListForNewQuestion(state: AnswerCreationState): AnswerCreationState {
            return {
                ...state,
                answers: [...state.answers, []]
            }
        },
    },
    effects: (dispatch) => ({
        addAnswer(indexQuestion: number, state) {
            const newAnswer = new Answer(
                new AnswerDescriptionValueObject(''),
                false,
            );

            // TODO decide if this validation is needed
            // const areAnswersValid = this.validateAllAnswersFromQuestion(indexQuestion);
            // if (!areAnswersValid) {
            //     return;
            // }

            dispatch.answerCreation.insertAnswer({
                    answer: newAnswer,
                    indexQuestion: indexQuestion
                } as PayloadInsertAnswer);
            dispatch.answerCreation.setShowError(false);
        },

        validateAllAnswersFromQuestion(indexQuestion: number, state): boolean {
            let flag: boolean = true;
            state.answerCreation.answers[indexQuestion].map((answer) => {
                const isDescriptionValid = typeof answer.description.value === 'string';

                if (!isDescriptionValid) {
                    dispatch.answerCreation.setShowError(true);
                    flag = false;
                    return;
                }
            });

            return flag;
        },
    }),
});
