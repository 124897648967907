import * as React from 'react';
import {Avatar, Grid, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Dispatch} from "../../redux/store";
import {UrlRoutes} from "../utils/UrlRoutes";
import {useNavigate} from "react-router-dom";
import {LocalStorage} from "../../../domain/repositories/LocalStorage";

export function HomePageCandidate(this: any) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<Dispatch>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function handleLogout() {
        navigate(UrlRoutes.INDEX_PAGE)
        dispatch.authentication.logout();
    }

    const currentUser = LocalStorage.getCurrentUser();

    return (
        <>
            <Grid
                item
                container
                justifyContent='space-between'
                wrap='nowrap'
            >
                <Grid item>
                    <Typography
                        variant="h3"
                        component="div"
                        gutterBottom
                    >
                        {t('homePage.title')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    width='fit-content'
                    wrap='nowrap'
                >
                    <Grid
                        item
                        container
                        width='fit-content'
                        alignItems="center"
                        direction="column"
                    >
                        <Avatar
                            alt={t('homePage.username')}
                        />
                        <Typography>
                            <span>
                                {'Candidate ' + currentUser.userName}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleMenuClick}>
                            <MoreVertIcon fontSize="large"/>
                        </IconButton>
                        <Menu open={menuOpen} onClose={handleMenuClose} anchorEl={anchorEl}>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                container
                flexGrow={1}
                direction='column'
                spacing={3}
            >
                <Typography>HOME PAGE CANDIDATE</Typography>
            </Grid>
        </>
    );
}
