import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import {Question} from "../../../domain/entities/Question";
import {QuestionDescriptionValueObject} from "../../../domain/value_objects/QuestionDescriptionValueObject";
import {QuestionDifficultyRankingValueObject} from "../../../domain/value_objects/QuestionDifficultyRankingValueObject";

export type QuestionCreationState = {
  showErrors: boolean,
  currentPage: number,
  questionAmount: number,
  questions: Question[],
}

export const questionCreation = createModel<RootModel>()({
  state: {
    showErrors: false,
    currentPage: 0,
    questionAmount: 0,
    questions: [],
  } as QuestionCreationState,
  reducers: {
    setShowError(state: QuestionCreationState, shouldShowErrors: boolean) {
      return {
        ...state,
        showErrors: shouldShowErrors,
      }
    },
    insertQuestion(state: QuestionCreationState, question: Question) {
      return {
        ...state,
        questionAmount: state.questions.length + 1,
        questions: [...state.questions, question],
      }
    },
    updatePage(state: QuestionCreationState, newPageNumber: number): QuestionCreationState {
      return {
        ...state,
        currentPage: newPageNumber,
      }
    },
    updateQuestionDescription(state: QuestionCreationState, input: string) {
      return {
        ...state,
        questions: state.questions.map((question, index) => {
          if (index === (state.currentPage - 1)) {
            question.description = new QuestionDescriptionValueObject(input);
          }
          return question;
        })
      }
    },
    updateCurrentQuestionRanking(state: QuestionCreationState, input: string) {
      return {
        ...state,
        questions: state.questions.map((question, index) => {
          if (index === (state.currentPage - 1)) {
            question.difficultyRanking = new QuestionDifficultyRankingValueObject(input);
          }
          return question;
        })
      }
    }
  },
  effects: (dispatch) => ({
    addQuestion(_: void, state) {
      const newQuestion = new Question(
        new QuestionDescriptionValueObject(''),
        new QuestionDifficultyRankingValueObject('')
      );

      const pageNumber: number = state.questionCreation.currentPage;
      const questions: Question[] = state.questionCreation.questions;
      const question: Question | undefined = questions[pageNumber-1];

      if (question !== undefined) {
        const isDescriptionValid = typeof question.description.value === 'string';
        const isDifficultyRankingValid = typeof question.difficultyRanking.value === 'string';
        const areAnswersValid = dispatch.answerCreation.validateAllAnswersFromQuestion(pageNumber-1);

        if (!isDescriptionValid) {
          return;
        }

        if (!isDifficultyRankingValid) {
          dispatch.questionCreation.setShowError(true);
          return;
        }

        if (!areAnswersValid) {
          dispatch.questionCreation.setShowError(true);
          return;
        }
      }

      dispatch.questionCreation.insertQuestion(newQuestion);
      dispatch.questionCreation.updatePage(state.questionCreation.questionAmount + 1);
      dispatch.questionCreation.setShowError(false);
      dispatch.answerCreation.addAnswerListForNewQuestion();
    },
  }),
});
