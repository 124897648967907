import * as React from 'react';
import {Button, Divider, Grid, Pagination, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../../redux/store";
import {QuestionDisplay} from "./QuestionDisplay/QuestionDisplay";
import {Question} from "../../../../domain/entities/Question";

type Props = {};

export const QuestionCreation = (_: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<Dispatch>();

  const page: number = useSelector((state: RootState) => state.questionCreation?.currentPage);
  const questionAmount: number = useSelector((state: RootState) => state.questionCreation?.questionAmount);
  const questions: Question[] = useSelector((state: RootState) => state.questionCreation?.questions);

  const currentQuestion: Question | undefined = questions[page - 1];

  return (
    <>
      <Grid item xs={10}>
        <Typography variant="h6" sx={{}}>
          {t('creationPage.questionCreation.sectionTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{
          marginBottom: "1vh"
        }}/>
      </Grid>
      <Grid item container direction="column" xs={12} spacing={2}>
        <Grid item container justifyContent="space-between">
          <Button
            variant="outlined"
            onClick={() => dispatch.questionCreation.addQuestion()}
          >
            {t('creationPage.questionCreation.newQuestionButton')}
          </Button>
          <Pagination
            count={questionAmount}
            page={page}
            onChange={(_, pageNumber) => dispatch.questionCreation.updatePage(pageNumber)}
          />
        </Grid>
        {currentQuestion !== undefined && (
          <Grid item>
            <QuestionDisplay
              question={currentQuestion}
              questionNumber={page}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
