import 'reflect-metadata';
import {Assessment} from "../../domain/entities/Assessment";
import {AssessmentDto} from "../dto/AssessmentDto";

export class AssessmentDtoMapper {
  static dtoToEntity(dto: AssessmentDto): Assessment {
    return new Assessment(
      dto.id,
      dto.name,
      dto.description,
      dto.maxTimeMinutes,
      dto.questions.length,
    );
  }
}
