import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import {
    FetchAllAssessmentsUseCase
} from "../../../domain/use_cases/FetchAllAssessmentsUseCase/FetchAllAssessmentsUseCase";
import {Assessment} from "../../../domain/entities/Assessment";
import {container} from "tsyringe";
import {DeleteAssessmentUseCase} from "../../../domain/use_cases/DeleteAssessmentUseCase/DeleteAssessmentUseCase";

const fetchAllAssessmentsUseCase: FetchAllAssessmentsUseCase = container.resolve(FetchAllAssessmentsUseCase);
const deleteAssessmentUseCase: DeleteAssessmentUseCase = container.resolve(DeleteAssessmentUseCase);

export type AssessmentsState = {
    assessments: Assessment[],
    isLoading: boolean,
}

export const assessments = createModel<RootModel>()({
  state: {
    assessments: [],
    isLoading: false,
  } as AssessmentsState,
  reducers: {
    setAssessments(state: AssessmentsState, payload: Array<Assessment>): AssessmentsState {
      return {
        ...state,
        assessments: payload,
      }
    },
    setIsLoading(state: AssessmentsState, payload: boolean): AssessmentsState {
      return {
        ...state,
        isLoading: payload,
      }
    }
  },

  effects: (dispatch) => ({
    async loadAssessments() {
      dispatch.assessments.setIsLoading(true);

      const allAssessments: Assessment[] = await fetchAllAssessmentsUseCase.execute();
      dispatch.assessments.setAssessments(allAssessments);

      dispatch.assessments.setIsLoading(false);
    },
    async deleteAssessment(payload: Assessment) {
      dispatch.assessments.setIsLoading(true);

      await deleteAssessmentUseCase.execute(payload);
      dispatch.assessments.loadAssessments();
    },
  }),
});
