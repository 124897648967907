import * as React from 'react';
import {Divider, Grid, Typography} from "@mui/material";
import {TitleInput} from "./TitleInput";
import {DescriptionInput} from "./DescriptionInput";
import {TimeLimitInput} from "./TimeLimitInput";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

type Props = {};

export const AssessmentDetails = (_: Props) => {
  const title = useSelector((state: RootState) => state.assessmentCreation?.title);
  const timeLimit = useSelector((state: RootState) => state.assessmentCreation?.timeLimit);
  const description = useSelector((state: RootState) => state.assessmentCreation?.description);
  const {t} = useTranslation();

  return (
    <>
      <Grid item xs={10}>
        <Typography variant="h6">
          {t('creationPage.assessmentDetails.sectionTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{
          marginBottom: "1vh"
        }}/>
      </Grid>
      <Grid item container direction="column" xs={6} spacing={2}>
        <Grid item>
          <TitleInput value={title}/>
        </Grid>
        <Grid item>
          <TimeLimitInput value={timeLimit}/>
        </Grid>
      </Grid>
      <Grid item container xs={6}>
        <DescriptionInput value={description}/>
      </Grid>
    </>
  );
};
