import * as React from 'react';
import {Question} from "../../../../../domain/entities/Question";
import {Grid, Typography} from "@mui/material";
import {DifficultyRankingSelect} from "./DifficultyRankingSelect/DifficultyRankingSelect";
import {DescriptionInput} from "./DescriptionInput/DescriptionInput";
import {useTranslation} from "react-i18next";
import {AnswerCreation} from "./AnswerCreation/AnswerCreation";

type Props = {
  question: Question,
  questionNumber: number,
};

export const    QuestionDisplay = (props: Props) => {
  const {t} = useTranslation();

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">
          {`${t('creationPage.questionDisplay.questionLabel')} ${props.questionNumber}`}
        </Typography>
      </Grid>
      <Grid item>
        <DescriptionInput description={props.question.description}/>
      </Grid>
      <Grid item>
        <DifficultyRankingSelect ranking={props.question.difficultyRanking}/>
      </Grid>
        <Grid item >
            <AnswerCreation/>
        </Grid>
    </Grid>
  );
};
