// @flow
import * as React from 'react';
import {Grid, Typography} from "@mui/material";
import {Answer} from "../../../../../../../domain/entities/Answer";
import {useTranslation} from "react-i18next";
import {AnswerDescriptionInput} from "./AnswerDescriptionInput";

type Props = {
    answer: Answer,
    answerNumber: number,
};
export const AnswerDisplay = (props: Props) => {
    const {t} = useTranslation();

    return (

        <Grid item>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="subtitle1">
                        {`${t('creationPage.answerDisplay.questionLabel')} ${props.answerNumber + 1}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <AnswerDescriptionInput
                        description={props.answer.description}
                        index={props.answerNumber}/>
                </Grid>
            </Grid>
        </Grid>

    );
};